<template>
    <div v-if="loaded" id="reviews">
        <div v-if="authenticated && author.review && skip === 0" class="results user-reviews">
            <div class="user-review-card">
                <div class="user-review-title">Your Review</div>
                <review v-on:reload="reload()" :value="author.review" :authenticated="authenticated" :email="author.email" :plugin="plugin" :pinned="true" class="user-review" />
            </div>
        </div>
        <div v-else-if="authenticated && !show.new">
            <div class="review-actions">
                <div class="user-review-card">
                    <div class="action-text">Do you use this plugin?</div>
                    <div v-on:click="review()" class="button button-primary">Write a Review</div>
                </div>
            </div>
        </div>
        <div v-else-if="authenticated">
            <div class="user-review-card">
                <div class="review-text-form">
                    <div v-if="errors.length > 0" class="errors">
                        <span v-for="(error, index) in errors" :key="`error_${index}`">{{ error }}</span>
                    </div>
                    <rating v-on:change="(value) => { data.rating = value }" v-model="data.rating" :editable="true" />
                    <br>
                    <textarea class="review-textarea" v-model="data.body" ref="new"></textarea>
                    <div class="review-edit-actions">
                        <div v-on:click="save()" class="button button-primary">Save</div>
                        <div v-on:click="cancel()" class="button">Cancel</div>
                        <div class="review-edit-flags">Markdown Enabled</div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="results" class="results">
            <review v-for="(item, index) in results" :key="`review_${index}`" v-on:reload="reload()" :value="item" :authenticated="authenticated" :email="author.email" :plugin="plugin" />
            <div v-if="total > 1 && pages.length > 1" class="result-pagination-container">
                <div v-if="pages[0] > 0" v-on:click="paginate(0, 20)" class="pagination-button">1</div>
                <div v-if="pages[0] > 0" class="pagination-more">...</div>
                <div v-for="(page, index) in pages" :key="`page_bottom_${index}`" v-on:click="paginate(page * 20, 20)" :class="`${page === current ? 'pagination-button-off' : 'pagination-button'}`">{{ page + 1 }}</div>
                <div v-if="pages[pages.length - 1] < total - 1" class="pagination-more">...</div>
                <div v-if="pages[pages.length - 1] < total - 1" v-on:click="paginate((total - 1) * 20, 20)" class="pagination-button">{{ total }}</div>
            </div>
            <div v-else class="reviews-footer"></div>
        </div>
        <div v-else-if="!show.new && !author.review" class="empty">
            <div class="empty-inner">
                <h1>This plugin has no reviews</h1>
                <h2>:(</h2>
            </div>
        </div>
    </div>
</template>

<script>
    import Review from "@/components/review.vue";
    import Rating from "@/components/rating.vue";

    export default {
        name: "reviews",
        components: { "review": Review, "rating": Rating },
        props: { plugin: Object },

        data() {
            return {
                loaded: false,
                authenticated: false,
                author: {},
                results: [],
                count: 0,
                total: 0,
                current: 0,
                pages: [],
                skip: 0,
                limit: 20,
                data: { body: null, rating: 0 },
                show: { new: false },
                errors: [],
            };
        },

        watch: {
            skip: async function () {
                await this.fetch();
            },

            limit: async function () {
                await this.fetch();
            },
        },

        async mounted() {
            this.authenticated = ((await this.$authenticated) && (await this.$hasRole("USERS")));

            if (this.authenticated) {
                this.author.name = await this.$profile("given_name");
                this.author.email = await this.$profile("email");

                if (!this.author.name || this.author.name === "") {
                    this.author.name = (this.author.email || "").split("@")[0];
                }

                this.author.picture = await this.$profile("picture");
                this.author.review = (((await this.api.get(`/${this.plugin.name}/reviews?author=${encodeURIComponent(this.author.email)}`)) || {}).results || [])[0];
            }

            this.skip = parseInt(this.$route.query.skip, 10) || 0;
            this.limit = parseInt(this.$route.query.limit, 10) || 20;

            await this.fetch();

            this.loaded = true;
        },

        methods: {
            async reload() {
                if (this.authenticated) this.author.review = (((await this.api.get(`/${this.plugin.name}/reviews?author=${encodeURIComponent(this.author.email)}`)) || {}).results || [])[0];

                await this.fetch();
            },

            async fetch() {
                const response = await this.api.get(`/${this.plugin.name}/reviews?skip=${this.skip}&limit=${this.limit}`);

                this.results = response.results;
                this.count = response.count;

                if (this.authenticated && this.author.review) {
                    this.results = this.results.filter((review) => review.author.email !== this.author.review.author.email);
                    this.count -= 1;
                }

                if (this.results && this.results.length <= 0) this.results = null;

                this.pages = [];

                this.current = parseInt(this.limit, 10) > 0 ? parseInt(this.skip, 10) / parseInt(this.limit, 10) : 0;
                this.total = Math.min(Math.ceil(parseInt(this.limit, 10) > 0 ? this.count / parseInt(this.limit, 10) : 0), 20);

                let start = this.current - 2;
                let end = start + 4;

                if (start < 0) {
                    start = 0;
                    end = start + 4;
                }

                if (end > this.total) {
                    end = this.total;
                    start = end - 4;
                }

                if (start < 0) start = 0;

                for (let i = start; i < end; i += 1) {
                    this.pages.push(i);
                }

                this.$emit("top");
            },

            paginate(skip, limit) {
                this.skip = parseInt(skip, 10) || 0;
                this.limit = parseInt(limit, 10) || 20;
            },

            review() {
                this.data.body = null;
                this.data.rating = 4;
                this.show.new = true;

                setTimeout(() => { this.$refs.new.focus(); }, 10);
            },

            cancel() {
                this.data.body = null;
                this.data.rating = 0;
                this.show.new = false;
            },

            async save() {
                if (!this.data.rating || this.data.rating <= 0) this.errors.push("You must select at least one star.");
                if (!this.data.body || this.data.body === "") this.errors.push("You must submit a review.");

                if (this.errors.length === 0) {
                    await this.api.put(`/${this.plugin.name}/reviews?author=${encodeURIComponent(this.author.email)}`, {
                        name: this.plugin.name,
                        author: {
                            name: this.author.name,
                            email: this.author.email,
                            picture: this.author.picture
                        },
                        rating: this.data.rating,
                        body: this.data.body,
                    });

                    await this.reload();

                    this.cancel();
                }
            },
        },
    };
</script>

<style scoped>
    #reviews {
        display: flex;
        flex-direction: column;
    }

    #reviews .results {
        flex: 1;
        display: flex;
        flex-direction: column;
        margin: -20px 0 0 0;
    }

    #reviews .results .result-pagination-container {
        height: 50px;
        display: flex;
        padding: 10px 0 10px 14px;
        flex-direction: row;
        justify-content: flex-start;
        box-sizing: border-box;
        align-content: center;
        align-items: center;
        user-select: none;
    }

    #reviews .results .pagination-more {
        font-size: 14px;
        margin: 0 0 0 3px;
        cursor: default;
        user-select: none;
    }

    #reviews .results .pagination-button {
        padding: 4px 10px;
        font-size: 14px;
        margin: 0 0 0 3px;
        background: #fff;
        border: 1px #e5e5e5 solid;
        border-radius: 3px;
        cursor: pointer;
        user-select: none;
    }

    #reviews .results .pagination-button:hover {
        box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.24),
            0 2px 1px -1px rgba(0, 0, 0, 0.22), 0 1px 3px 1px rgba(0, 0, 0, 0.3);
    }

    #reviews .results .pagination-button-off {
        padding: 4px 10px;
        font-size: 14px;
        margin: 0 0 0 3px;
        color: #fff;
        background: #feb400;
        border: 1px #feb400 solid;
        border-radius: 3px;
        cursor: default;
        user-select: none;
    }

    #reviews .review-actions {
        padding: 0 0 20px 0;
    }

    #reviews .review-actions .action-text {
        margin: 0 10px;
        font-size: 14px;
    }

    #reviews .review-actions .user-review-card {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-content: center;
        align-items: center;
        padding: 10px 0;
    }

    #reviews .user-reviews {
        margin: 0;
    }

    #reviews .user-review-card {
        border: 1px #e5e5e5 solid;
        border-radius: 3px;
        margin: 0 0 20px 0;
    }

    #reviews .user-review-card .user-review-title {
        font-size: 14px;
        font-weight: 700;
        margin: 20px 20px 0 20px;
        color: #feb400;
    }

    #reviews .user-review-card .user-review {
        border: 0 none;
    }

    #reviews .review-text-form {
        font-size: 14px;
        margin: 0;
        position: relative;
        padding: 20px;
    }

    #reviews .review-edit-actions {
        display: flex;
        flex-direction: row;
        margin: 10px 0 0 0;
    }

    #reviews .review-edit-actions .review-edit-flags {
        flex: 1;
        text-align: right;
        font-size: 12px;
        color: #9d9d9d;
        user-select: none;
        cursor: default;
    }

    #reviews .review-textarea {
        height: 200px;
        width: 100%;
        font-size: 14px;
        padding: 0 0 14px 0;
        border-top: 0 none;
        border-right: 0 none;
        border-bottom: 1px #d4d4d4 solid;
        border-left: 0 none;
        overflow: auto;
        resize: none;
    }

    #reviews .review-textarea:focus {
        border-bottom: 2px #feb400 solid;
        padding: 0 0 13px 0;
        outline: 0 none !important;
    }

    #reviews .errors {
        display: flex;
        flex-direction: column;
        border: 1px #fff9e9 solid;
        padding: 7px;
        border-radius: 3px;
        margin: 0 0 10px 0;
        font-size: 14px;
        background: #fff9e9;
    }

    #reviews .reviews-footer {
        background: #fff;
        height: 2px;
        margin: -1px 0 0 0;
        z-index: 100;
    }

    #reviews .empty {
        flex: 1;
        display: flex;
        flex-direction: column;
    }

    #reviews .empty .empty-inner {
        margin: 0 auto;
        min-height: 20vh;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    #reviews .empty h1 {
        margin: 0;
        line-height: normal;
        font-weight: normal;
        font-size: 17px;
    }

    #reviews .empty h2 {
        margin: 10px 0 0 0;
        line-height: normal;
        font-size: 22px;
    }
</style>
