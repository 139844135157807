import "../lib/extentions";
import "../lib/dates";

import Vue from "vue";
import Request from "axios";
import Gtag from "vue-gtag";
import TrendChart from "vue-trend-chart";

import { Base64 } from "js-base64";

import Authentication from "../lib/auth";
import Markdown from "../lib/markdown";

import App from "./app.vue";
import Router from "./router";
import Security from "./security";

import Logo from "@/components/logo.vue";
import Sitemap from "@/components/sitemap.vue";
import Searchbar from "@/components/searchbar.vue";

import "materialize-css";
import "@/assets/materialize.css";

(async () => {
    const UserManager = new Authentication();

    Vue.mixin({
        data: () => {
            return {
                api: {
                    async get(url) {
                        let token = await UserManager.acessToken();

                        if (!token) token = await Security.token();

                        return (await Request.get(`${process.env.API_URL}/api${url}`, { headers: { "Authorization": `${token.token_type} ${token.access_token}` } })).data;
                    },

                    async post(url, data) {
                        let token = await UserManager.acessToken();

                        if (!token) token = await Security.token();

                        return (await Request.post(`${process.env.API_URL}/api${url}`, data, { headers: { "Authorization": `${token.token_type} ${token.access_token}` } })).data;
                    },

                    async put(url, data) {
                        let token = await UserManager.acessToken();

                        if (!token) token = await Security.token();

                        return (await Request.put(`${process.env.API_URL}/api${url}`, data, { headers: { "Authorization": `${token.token_type} ${token.access_token}` } })).data;
                    },

                    async delete(url) {
                        let token = await UserManager.acessToken();

                        if (!token) token = await Security.token();

                        return (await Request.delete(`${process.env.API_URL}/api${url}`, { headers: { "Authorization": `${token.token_type} ${token.access_token}` } })).data;
                    }
                },

                $token: {
                    id() {
                        UserManager.idToken().then((results) => resolve(results), () => resolve(null));
                    },

                    access() {
                        UserManager.acessToken().then((results) => resolve(results), () => resolve(null));
                    }
                }
            }
        },

        computed: {
            $authenticated: () => {
                return new Promise((resolve) => {
                    UserManager.authenticated().then((result) => resolve(result), () => resolve(false));
                });
            }
        },

        methods: {
            $user() {
                return new Promise((resolve) => {
                    UserManager.user().then((results) => resolve(results), () => resolve(null));
                });
            },

            async $login() {
                const request = await UserManager.logoutRedirect();

                await Request.get(request);
                await UserManager.removeUser();

                UserManager.login();
            },

            async $logout() {
                UserManager.logout();
            },

            $profile(claim) {
                return new Promise((resolve) => {
                    UserManager.profile(claim).then((result) => resolve(result), () => resolve(null));
                });
            },

            $hasRole(role) {
                return new Promise((resolve) => {
                    UserManager.roles().then((result) => resolve(result.indexOf(role) >= 0), () => resolve(false));
                });
            },

            $markdown(value, repository, branded) {
                return Markdown.format(value, repository, branded);
            },

            $encode(value) {
                if (!value) return "";

                while (value.endsWith("\\") || value.endsWith("/")) {
                    value = value.slice(0, -1);
                }

                while (value.startsWith("*")) {
                    value = value.substring(1);
                }

                if (!value || value === "") return "";

                return Base64.encodeURI(value);
            },

            $decode(value) {
                try {
                    return Base64.decode(value);
                } catch (error) {
                    return "";
                }
            }
        }
    });

    Vue.use(Gtag, { config: { id: "UA-145462356-1" }, appName: "Hoobs Plugin Page", pageTrackerScreenviewEnabled: true }, Router);
    Vue.use(TrendChart);

    Vue.component("logo", Logo);
    Vue.component("sitemap", Sitemap);
    Vue.component("searchbar", Searchbar);

    Vue.config.productionTip = false;

    new Vue({ router: Router, render: (view) => view(App) }).$mount("#app");
})();
