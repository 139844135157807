const Showdown = require("showdown");
const Emoji = require("./emoji");
const Highlight = require("showdown-highlight");
const Git = require("./git");

Showdown.setFlavor("github");

const converter = new Showdown.Converter({
    extensions: [Emoji, Highlight],
    tables: true,
    simplifiedAutoLink: true,
    excludeTrailingPunctuationFromURLs: true
});

exports.convert = function(value) {
    if (!value || value === "") return "";

    return converter.makeHtml(value);
};

exports.format = function(value, repository, branded) {
    if (!value || value === "") return "";

    let results = converter.makeHtml(value);

    results = results.replace(/```[a-zA-Z ]*\n/gi, (match) => `${match.trim().toLowerCase()}\n`);

    if (branded) {
        results = results.replace(/: <a href="https\:\/\/github\.com\/homebridge\/homebridge">https:\/\/github\.com\/homebridge\/homebridge<\/a>/gmi, "")
        results = results.replace(/: <a href="https\:\/\/github\.com\/nfarina\/homebridge">https:\/\/github\.com\/nfarina\/homebridge<\/a>/gmi, "")
        results = results.replace(/: <a href="https\:\/\/homebridge\.io">https:\/\/homebridge\.io<\/a>/gmi, "")
        results = results.replace(/ <a href="https\:\/\/github\.com\/homebridge\/homebridge">https:\/\/github\.com\/homebridge\/homebridge<\/a>/gmi, "")
        results = results.replace(/ <a href="https\:\/\/github\.com\/nfarina\/homebridge">https:\/\/github\.com\/nfarina\/homebridge<\/a>/gmi, "")
        results = results.replace(/ <a href="https\:\/\/homebridge\.io">https:\/\/homebridge\.io<\/a>/gmi, "")
        results = results.replace(/<a href="https\:\/\/github\.com\/homebridge\/homebridge">https:\/\/github\.com\/homebridge\/homebridge<\/a> /gmi, "")
        results = results.replace(/<a href="https\:\/\/github\.com\/nfarina\/homebridge">https:\/\/github\.com\/nfarina\/homebridge<\/a> /gmi, "");
        results = results.replace(/<a href="https\:\/\/homebridge\.io">https:\/\/homebridge\.io<\/a> /gmi, "");

        results = results.replace(/: <a href="https\:\/\/github\.com\/homebridge\/homebridge">homebridge<\/a>/gmi, "")
        results = results.replace(/: <a href="https\:\/\/github\.com\/nfarina\/homebridge">homebridge<\/a>/gmi, "")
        results = results.replace(/: <a href="https\:\/\/homebridge\.io">homebridge<\/a>/gmi, "")
        results = results.replace(/ <a href="https\:\/\/github\.com\/homebridge\/homebridge">homebridge<\/a>/gmi, "")
        results = results.replace(/ <a href="https\:\/\/github\.com\/nfarina\/homebridge">homebridge<\/a>/gmi, "")
        results = results.replace(/ <a href="https\:\/\/homebridge\.io">homebridge<\/a>/gmi, "")
        results = results.replace(/<a href="https\:\/\/github\.com\/homebridge\/homebridge">homebridge<\/a> /gmi, "")
        results = results.replace(/<a href="https\:\/\/github\.com\/nfarina\/homebridge">homebridge<\/a> /gmi, "");
        results = results.replace(/<a href="https\:\/\/homebridge\.io">homebridge<\/a> /gmi, "");

        results = results.replace(/: https\:\/\/github\.com\/homebridge\/homebridge/gmi, "")
        results = results.replace(/: https\:\/\/github\.com\/nfarina\/homebridge/gmi, "")
        results = results.replace(/: https\:\/\/homebridge\.io/gmi, "")
        results = results.replace(/ https\:\/\/github\.com\/homebridge\/homebridge/gmi, "")
        results = results.replace(/ https\:\/\/github\.com\/nfarina\/homebridge/gmi, "");
        results = results.replace(/ https\:\/\/homebridge\.io/gmi, "");

        results = results.replace(/homebridge to /gmi, "")
        results = results.replace(/homebridge plugin /gmi, "Plugin ")
        results = results.replace(/homebridge /gmi, "");

        results = results.replace(/ for homebridge/gmi, "")
        results = results.replace(/ in homebridge/gmi, "")
        results = results.replace(/ with homebridge/gmi, "");
    }

    if ((repository || {}).url && (repository.url.includes("github.com") || repository.url.includes("gitlab.com"))) {
        const identifier = Git.repository(repository);

        if (identifier && repository.url.includes("github.com")) {
            results = Git.replacement(results, /<img.*?src=["'](.*?)["'][^>]+>|<img.*?src=["'](.*?)["']>/gmi, /.*src=["']([^"']*)["'].*/, `https://raw.githubusercontent.com/${identifier}/HEAD`);
            results = Git.replacement(results, /<a.*?href=["'](.*?)["'][^>]+>|<a.*?href=["'](.*?)["']>/gmi, /.*href=["']([^"']*)["'].*/, `https://github.com/${identifier}/blob/HEAD`);
        } else if (identifier && repository.url.includes("gitlab.com")) {
            results = Git.replacement(results, /<img.*?src=["'](.*?)["'][^>]+>|<img.*?src=["'](.*?)["']>/gmi, /.*src=["']([^"']*)["'].*/, `https://gitlab.com/${identifier}/-/raw/HEAD`);
            results = Git.replacement(results, /<a.*?href=["'](.*?)["'][^>]+>|<a.*?href=["'](.*?)["']>/gmi, /.*href=["']([^"']*)["'].*/, `https://gitlab.com/${identifier}/-/blob/HEAD`);
        }
    }

    return results;
};
