import OIDC from "oidc-client";

const UserManager = new OIDC.UserManager({
    authority: process.env.ISSUER_URL,
    client_id: process.env.CLIENT_ID,
    redirect_uri: `${process.env.API_URL}/callback.html`,
    silent_redirect_uri: `${process.env.API_URL}/renew.html`,
    response_type: "code",
    scope: `openid profile roles email ${process.env.API_SCOPE}`,
    post_logout_redirect_uri: `${process.env.API_URL}/`,
});

export default class Authentication {
    renewToken() {
        return new Promise((resolve, reject) => {
            UserManager.signinSilent({ silentRequestTimeout: process.env.ENVIORNMENT === "development" ? 500 : 10000 }).then((user) => {
                console.log(user);
                if (!user || user.expired) {
                    this.login();
                } else {
                    resolve(user);
                }
            }).catch((error) => reject(error));
        });
    }

    user() {
        return new Promise((resolve) => {
            UserManager.getUser().then((user) => {
                if (user && user.expired) {
                    this.renewToken().then((refreshed) => resolve(refreshed)).catch(() => resolve(null));
                } else {
                    resolve(user);
                }
            }).catch(() => resolve(null));
        });
    }

    authenticated() {
        return new Promise((resolve) => {
            this.user().then((user) => resolve(user ? true : false));
        });
    }

    login() {
        UserManager.signinRedirect().catch((error) => console.log(error));
    }

    logout() {
        UserManager.signoutRedirect({
            post_logout_redirect_uri: `${process.env.API_URL}/`
        }).catch((error) => console.log(error));
    }

    logoutRedirect() {
        return new Promise((resolve, reject) => {
            UserManager.createSignoutRequest().then((results) => resolve(results)).catch((error) => reject(error));
        });
    }

    removeUser() {
        return new Promise((resolve, reject) => {
            UserManager.getUser().then((user) => {
                UserManager._revokeInternal(user).then(() => {
                    UserManager.removeUser().then(() => {
                        UserManager.clearStaleState();

                        resolve();
                    }).catch((error) => reject(error));
                }).catch((error) => reject(error));
            }).catch((error) => reject(error));
        });
    }

    profile(key) {
        return new Promise((resolve) => {
            this.user().then((user) => resolve(user ? user.profile[key] : null));
        });
    }
    
    idToken() {
        return new Promise((resolve) => {
            this.user().then((user) => resolve(user ? user.id_token : null));
        });
    }

    acessToken() {
        return new Promise((resolve) => {
            this.user().then((user) => resolve(user ? {
                access_token: user.access_token,
                expires_in: user.expires_in,
                token_type: user.token_type,
                scope: user.scope
            } : null));
        });
    }

    roles() {
        return new Promise((resolve) => {
            this.user().then((user) => {
                const roles = [];
                const claims = Object.keys((user || {}).profile || {});

                for (let i = 0; i < claims.length; i += 1) {
                    if (claims[i] === "role" || claims[i].endsWith("/role")) {
                        if (Array.isArray(user.profile[claims[i]])) {
                            for (let j = 0; j < user.profile[claims[i]].length; j++) {
                                if (roles.indexOf(user.profile[claims[i]][j]) === -1) roles.push(user.profile[claims[i]][j]);
                            }
                        } else {
                            if (roles.indexOf(user.profile[claims[i]]) === -1) roles.push(user.profile[claims[i]]);
                        }
                    }
                }

                return resolve(roles);
            });
        });
    }
}
