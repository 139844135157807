<template>
    <div id="rating">
        <div v-on:click="$emit('change', 1)" :class="`${editable ? 'star editable': 'star'}`">
            <div class="icon">star</div>
            <div v-if="value >= 1" class="icon earned">star</div>
            <div v-else-if="value > 0" class="icon earned" :style="`width: ${(value * 100).toFixed(2)}%`">star</div>
        </div>
        <div v-on:click="$emit('change', 2)" :class="`${editable ? 'star editable': 'star'}`">
            <div class="icon">star</div>
            <div v-if="value >= 2" class="icon earned">star</div>
            <div v-else-if="value > 1" class="icon earned" :style="`width: ${((value - 1) * 100).toFixed(2)}%`">star</div>
        </div>
        <div v-on:click="$emit('change', 3)" :class="`${editable ? 'star editable': 'star'}`">
            <div class="icon">star</div>
            <div v-if="value >= 3" class="icon earned">star</div>
            <div v-else-if="value > 2" class="icon earned" :style="`width: ${((value - 2) * 100).toFixed(2)}%`">star</div>
        </div>
        <div v-on:click="$emit('change', 4)" :class="`${editable ? 'star editable': 'star'}`">
            <div class="icon">star</div>
            <div v-if="value >= 4" class="icon earned">star</div>
            <div v-else-if="value > 3" class="icon earned" :style="`width: ${((value - 3) * 100).toFixed(2)}%`">star</div>
        </div>
        <div v-on:click="$emit('change', 5)" :class="`${editable ? 'star editable': 'star'}`">
            <div class="icon">star</div>
            <div v-if="value >= 5" class="icon earned">star</div>
            <div v-else-if="value > 4" class="icon earned" :style="`width: ${((value - 4) * 100).toFixed(2)}%`">star</div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "rating",
        props: { value: Number, editable: Boolean },
    };
</script>

<style scoped>
    #rating {
        display: flex;
        flex-direction: row;
        align-content: center;
        align-items: center;
        user-select: none;
        cursor: default;
    }

    #rating .star {
        width: 17px;
        height: 17px;
        position: relative;
        overflow: hidden;
    }

    #rating .editable {
        cursor: pointer;
    }

    #rating .star .icon {
        font-size: 17px;
        color: #e5e5e5;
    }

    #rating .editable:hover .icon {
        color: #d3d3d3;
    }

    #rating .star .earned {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 17px;
        color: #feb400;
        overflow: hidden;
    }

    #rating .editable:hover .earned {
        color: #feb400;
    }
</style>
