<template>
    <div id="home">
        <searchbar />
        <div class="content">
            <div class="featured">
                <div class="column">
                    <div class="title">
                        <h1>
                            <span class="icon">emoji_events</span>Featured
                        </h1>
                    </div>
                    <router-link v-for="(item, index) in featured" :key="`festured_${index}`" :to="`/plugin/${item.name}`">
                        <div class="plugin-icon">
                            <img :src="icon(item)" />
                        </div>
                        <div class="link-detail">
                            <div class="item-name" v-html="cleanName(item.name)"></div>
                            <div v-if="item.description" class="item-subtitle markdown-title" v-html="item.description"></div>
                        </div>
                    </router-link>
                </div>
                <div class="column">
                    <div class="title">
                        <h1>
                            <span class="icon">whatshot</span>Popular
                        </h1>
                    </div>
                    <router-link v-for="(item, index) in popular" :key="`popular_${index}`" :to="`/plugin/${item.name}`">
                        <div class="plugin-icon">
                            <img :src="icon(item)" />
                        </div>
                        <div class="link-detail">
                            <div class="item-name" v-html="cleanName(item.name)"></div>
                            <div v-if="item.description" class="item-subtitle markdown-title" v-html="item.description"></div>
                        </div>
                    </router-link>
                </div>
                <div class="column">
                    <div class="title">
                        <h1>
                            <span class="icon">pie_chart</span>Statistics
                        </h1>
                    </div>
                    <div v-if="stats.plugins" class="item">
                        <div class="item-subtitle">Total Plugins</div>
                        <div class="item-value">{{ stats.plugins.formatted }}</div>
                    </div>
                    <div v-if="stats.schemas" class="item">
                        <div class="item-subtitle">Visual Configuration Plugins</div>
                        <div class="item-value">{{ stats.schemas.formatted }}</div>
                    </div>
                    <div v-if="stats.latest" class="item">
                        <div class="item-subtitle">Latest Publish</div>
                        <div class="item-value">{{ new Date(stats.latest).age }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import crypto from "crypto";
    import identicon from "identicon.js";

    export default {
        name: "home",

        data() {
            return {
                featured: [],
                popular: [],
                stats: {},
            };
        },

        mounted() {
            this.$emit("nav");

            this.api.get("/feed/featured").then((response) => { this.featured = (response || {}).results.slice(0, 20) || []; });
            
            this.api.get("/feed/popular").then((response) => { this.popular = (response || {}).results || []; });
            this.api.get("/feed/stats").then((response) => { this.stats = (response || {}).results || []; });
        },

        methods: {
            cleanName(value) {
                return value.replace(/homebridge-/gi, "").replace(/-/gi, " ").replace(/\//gi, " / ").replace(/\w\S*/g, (item) => `${item.charAt(0).toUpperCase()}${item.substr(1).toLowerCase()}`);
            },

            icon(plugin) {
                if (plugin.icon) return plugin.icon;

                return `data:image/png;base64,${new identicon(crypto.createHash("md5").update(plugin.name).digest("hex"), 61).toString()}`;
            },
        },
    };
</script>

<style scoped>
    #home {
        flex: 1;
        display: flex;
        flex-direction: column;
        background: #fbfbfb;
    }

    #home .content {
        flex: 1;
        display: flex;
        flex-direction: column;
    }

    #home .featured {
        flex: 1;
        padding: 10px;
        display: grid;
        grid-template-columns: auto auto 20%;
    }

    #home .featured .column {
        margin: 10px;
        padding: 14px 20px 20px 20px;
        border: 1px #e5e5e5 solid;
        border-radius: 3px;
        background: #fff;
        overflow-x: hidden;
    }

    #home .featured .column .item {
        display: block;
        padding: 14px 0;
        border-bottom: 1px #e5e5e5 solid;
    }

    #home .featured .column .item:last-child {
        border-bottom: 0 none;
    }

    #home .featured .column a,
    #home .featured .column a:link,
    #home .featured .column a:active,
    #home .featured .column a:visited {
        display: flex;
        padding: 14px 0;
        border-bottom: 1px #e5e5e5 solid;
        text-decoration: none;
    }

    #home .featured .column a:last-child {
        border-bottom: 0 none;
    }

    #home .featured .column .link-detail {
        flex: 1;
    }

    #home .featured .column .plugin-icon {
        margin: 0 10px 0 0;
    }

    #home .featured .column .plugin-icon img {
        width: 35px;
        height: 35px;
        border-radius: 3px;
        box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.3);
    }

    #home .featured .column .item-name {
        margin: 2px 0 0 0;
        color: #3d3d3d;
        font-size: 14px;
        font-weight: bold;
    }

    #home .featured .column a:hover .item-name {
        color: #feb400;
    }

    #home .featured .column .item-subtitle {
        margin: 2px 0 0 0;
        color: #757575;
        font-size: 12px;
        overflow: hidden;
        text-transform: capitalize;
    }

    #home .featured .column .item-value {
        color: #3d3d3d;
        font-size: 17px;
        font-weight: bold;
    }

    #home .featured .column .item-published {
        margin: 2px 0 0 0;
        color: #757575;
        font-size: 10px;
    }

    #home .featured .column .title {
        border-bottom: 2px #feb400 solid;
    }

    #home .featured .column .title h1 {
        margin: 0 0 7px 0;
        font-size: 15px;
        display: flex;
        flex-direction: row;
        align-content: center;
        align-items: center;
    }

    #home .featured .column .title h1 .icon {
        font-size: 20px;
        margin: 0 4px 0 0;
    }

    @media (max-width: 815px) {
        #home .featured {
            display: block;
            flex-direction: unset;
        }
    }
</style>
