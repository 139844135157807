import Vue from "vue";
import Router from "vue-router";

import Authentication from "../lib/auth";

import Home from "./views/home.vue";
import Search from "./views/search.vue";
import Plugin from "./views/plugin.vue";

Vue.use(Router);

const manager = new Authentication();

const router = new Router({
    mode: "history",
    base: process.env.BASE_URL,
    routes: [{
        path: "/",
        name: "home",
        component: Home,
        meta: { public: true },
    },
    { path: "/search", redirect: "/" },
    {
        path: "/search/:query",
        name: "search",
        component: Search,
        props: true,
        meta: { public: true },
    },
    {
        path: "/plugin/:scope/:id",
        name: "scoped",
        component: Plugin,
        props: true,
        meta: { public: true },
    },
    {
        path: "/plugin/:id",
        name: "plugin",
        component: Plugin,
        props: true,
        meta: { public: true },
    },
    { path: "/:scope/:id", redirect: "/plugin/:scope/:id" },
    { path: "/:id", redirect: "/plugin/:id" }],
});

router.beforeEach((to, _from, next) => {
    manager.user().then((user) => {
        if (user) {
            manager.roles().then((results) => {
                if (to.meta.role && results.indexOf(to.meta.role) === -1) {
                    next("/");
                } else {
                    next();
                }
            });
        } else {
            manager.renewToken().then(() => {
                if (to && to.path) {
                    window.location.href = to.path;
                } else {
                    window.location.reload();
                }
            }).catch(() => {
                if (to.meta.role) {
                    next("/");
                } else {
                    next();
                }
            });
        }
    });
});

export default router;
