<template>
    <div id="review">
        <div v-if="!show.edit && !show.respond" class="review-title">
            <div class="review-user">
                <img class="review-avitar" :src="value.author.picture" />
            </div>
            <div class="review-details">
                <rating :value="value.rating" />
                <div class="review-age">
                    Reviewed by
                    <span class="review-name" v-html="value.author.name"></span>
                    on {{ new Date(value.date).display }}
                </div>
            </div>
            <div v-if="authenticated" class="review-actions">
                <div v-if="pinned && value.author.email === email" v-on:click="edit()" class="review-action">
                    <div class="icon">edit</div>
                    <span>Edit</span>
                </div>
                <div v-if="(owner || maintainer) && value.author.email !== email" v-on:click="respond()" class="review-action">
                    <div class="icon">reply</div>
                    <span>Respond</span>
                </div>
            </div>
        </div>
        <div v-if="authenticated && show.edit && !show.respond" class="review-text-form">
            <div v-if="errors.length > 0" class="errors">
                <span v-for="(error, index) in errors" :key="`error_${index}`" v-html="error"></span>
            </div>
            <rating v-on:change="(value) => { data.rating = value }" v-model="data.rating" :editable="true" />
            <br>
            <textarea class="review-textarea" v-model="data.body" ref="edit"></textarea>
            <div class="review-edit-actions">
                <div v-on:click="save('body')" class="button button-primary">Save</div>
                <div v-on:click="cancel()" class="button">Cancel</div>
                <div class="review-edit-flags">Markdown Enabled</div>
            </div>
        </div>
        <div v-else-if="authenticated && show.respond && !show.edit" class="review-text-form">
            <div v-if="errors.length > 0" class="errors">
                <span v-for="(error, index) in errors" :key="`error_${index}`" v-html="error"></span>
            </div>
            <textarea class="review-textarea" v-model="data.response" ref="response"></textarea>
            <div class="review-edit-actions">
                <div v-on:click="save('response')" class="button button-primary">Save</div>
                <div v-on:click="cancel()" class="button">Cancel</div>
                <div class="review-edit-flags">Markdown Enabled</div>
            </div>
        </div>
        <div v-else-if="pinned || value.author.email !== email" ref="content" class="review-text">
            <div class="review-body markdown" v-html="value.body"></div>
            <div v-if="value.response" class="review-response-title">Developer Response</div>
            <div v-if="value.response" class="review-response markdown" v-html="value.response"></div>
            <div ref="cover" class="review-cover"></div>
            <span v-on:click="expand()" ref="more" class="more-link">More...</span>
        </div>
    </div>
</template>

<script>
    import Rating from "@/components/rating.vue";

    export default {
        name: "review",
        components: { "rating": Rating },

        props: {
            pinned: Boolean,
            value: Object,
            plugin: Object,
            authenticated: Boolean,
            email: String,
        },

        data() {
            return {
                owner: false,
                maintainer: false,
                data: {
                    body: null,
                    response: null,
                    rating: null
                },
                show: { edit: false, respond: false },
                errors: [],
            };
        },

        mounted() {
            if (this.authenticated) {
                if (((this.plugin || {}).author || {}).email === this.email) this.owner = true;

                const maintainers = (this.plugin || {}).maintainers || [];

                for (let i = 0; i < maintainers.length; i += 1) {
                    if ((maintainers[i] || {}).email === this.email) this.maintainer = true;
                }
            }
        },

        methods: {
            expand() {
                this.$refs.content.className = "review-text";
                this.$refs.cover.style.display = "none";
                this.$refs.more.style.display = "none";
            },

            edit() {
                this.errors = [];

                this.data.body = this.value.markdown.body;
                this.data.rating = this.value.rating;

                this.show.respond = false;
                this.show.edit = true;

                setTimeout(() => { this.$refs.edit.focus(); }, 10);
            },

            respond() {
                this.errors = [];

                this.data.body = null;
                this.data.response = this.value.response;
                this.data.rating = null;

                this.show.edit = false;
                this.show.respond = true;

                setTimeout(() => { this.$refs.response.focus(); }, 10);
            },

            cancel() {
                this.data.body = null;
                this.data.response = null;
                this.data.rating = null;

                this.show.respond = false;
                this.show.edit = false;

                this.errors = [];

                setTimeout(() => {
                    if (this.$refs.content.offsetHeight >= 150) {
                        this.$refs.content.className = "review-text review-preview";
                        this.$refs.cover.style.display = "block";
                        this.$refs.more.style.display = "block";
                    } else {
                        this.$refs.content.className = "review-text";
                        this.$refs.cover.style.display = "none";
                        this.$refs.more.style.display = "none";
                    }
                }, 10);
            },

            save(field) {
                this.errors = [];

                switch (field) {
                    case "body":
                        if (!this.data.rating || this.data.rating <= 0) this.errors.push("You must select at least one star.");
                        if (!this.data.body || this.data.body === "") this.errors.push("You must submit a review.");

                        if (this.errors.length === 0) {
                            this.api.post(`/${this.plugin.name}/reviews/${this.value.id}`, { rating: this.data.rating, body: this.data.body }).finally(() => {
                                this.$emit("reload");
                                this.cancel();
                            });
                        }

                        break;

                    case "response":
                        this.api.post(`/${this.plugin.name}/reviews/${this.value.id}/respond`, { response: this.data.response }).finally(() => {
                            this.$emit("reload");
                            this.cancel();
                        });

                        break;
                    
                    default:
                        this.$emit("reload");
                        this.cancel();
                        break;
                }
            },
        },
    };
</script>

<style scoped>
    #review {
        padding: 20px;
        position: relative;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        border-bottom: 1px #e5e5e5 solid;
    }

    #review .review-title {
        display: flex;
        flex-direction: row;
        align-content: flex-end;
        align-items: flex-end;
    }

    #review .review-user {
        display: flex;
        flex-direction: row;
        align-content: center;
        align-items: center;
    }

    #review .review-avitar {
        width: 32px;
        height: 32px;
        margin: 0 10px 0 0;
        border-radius: 3px;
    }

    #review .review-details {
        display: flex;
        flex-direction: column;
    }

    #review .review-age {
        font-size: 14px;
        color: #9d9d9d;
        user-select: none;
        cursor: default;
    }

    #review .review-name {
        color: #3d3d3d;
    }

    #review .review-actions {
        display: flex;
        flex-direction: row;
        align-content: flex-end;
        align-items: flex-end;
        margin: 0 0 0 7px;
    }

    #review .review-action {
        display: flex;
        color: #777;
        flex-direction: row;
        align-content: center;
        align-items: center;
        margin: 0 0 0 7px;
        padding: 5px 7px;
        border: 1px #e5e5e5 solid;
        border-radius: 3px;
        user-select: none;
        cursor: pointer;
    }

    #review .review-action:hover {
        box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14),
            0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
    }

    #review .review-action .icon {
        font-size: 14px;
        margin: 0 4px 0 0;
    }

    #review .review-action span {
        font-size: 12px;
    }

    #review .review-preview {
        height: 100px;
        overflow: hidden;
    }

    #review .review-cover {
        width: 100%;
        height: 40px;
        border-bottom: 30px #fff solid;
        display: none;
        position: absolute;
        bottom: 0;
        left: 0;
        background: linear-gradient(to bottom, rgba(255, 255, 255, 0), #fff);
    }

    #review .more-link {
        display: none;
        position: absolute;
        bottom: 0;
        left: 0;
        color: #feb400;
        cursor: pointer;
        text-decoration: none;
    }

    #review .more-link:hover {
        text-decoration: underline;
    }

    #review .review-text {
        font-size: 14px;
        margin: 10px 0 0 0;
        position: relative;
    }

    #review .review-text-form {
        font-size: 14px;
        margin: 0;
        position: relative;
    }

    #review .review-edit-actions {
        display: flex;
        flex-direction: row;
        margin: 10px 0 0 0;
    }

    #review .review-edit-actions .review-edit-flags {
        flex: 1;
        text-align: right;
        font-size: 12px;
        color: #9d9d9d;
        user-select: none;
        cursor: default;
    }

    #review .review-body {
        padding: 0;
    }

    #review .review-response-title {
        font-size: 12px;
        font-weight: bold;
        margin: 20px 0 0 0;
    }

    #review .review-response {
        background-color: #f7f7f7;
        border-radius: 3px;
        padding: 14px;
    }

    #review .review-textarea {
        height: 200px;
        width: 100%;
        font-size: 14px;
        padding: 0 0 14px 0;
        border-top: 0 none;
        border-right: 0 none;
        border-bottom: 1px #d4d4d4 solid;
        border-left: 0 none;
        overflow: auto;
        resize: none;
    }

    #review .review-textarea:focus {
        border-bottom: 2px #feb400 solid;
        padding: 0 0 13px 0;
        outline: 0 none !important;
    }

    #review .errors {
        display: flex;
        flex-direction: column;
        border: 1px #fff9e9 solid;
        padding: 7px;
        border-radius: 3px;
        margin: 0 0 10px 0;
        font-size: 14px;
        background: #fff9e9;
    }
</style>