<template>
    <div id="app" v-on:click="() => { show.profile = false; show.navigation = false; }">
        <navigation :authenticated="authenticated" :picture="picture" v-on:menu="() => { show.profile = false; show.navigation = !show.navigation; }" v-on:profile="() => { show.navigation = false; show.profile = !show.profile; }" />
        <div v-if="show.profile" v-on:click.stop class="profile-menu">
            <div class="profile-account">
                <img :src="picture" class="profile-avatar" />
                <div class="profile-name">{{ name }}</div>
                <div class="profile-email">{{ email }}</div>
                <div class="profile-action">
                    <a :href="`${issuer}/profile`" target="_blank" class="button">Manage Account</a>
                    <div v-on:click="$logout()" class="button">Sign Out</div>
                </div>
            </div>
            <div class="legal-links">
                <a :href="`https://support.${domain}/docs/5e8f6c790ab68b0344e872d8`">Privacy Policy</a>
                <div class="link-seperator">|</div>
                <a :href="`https://support.${domain}/docs/5e7649bee87d1e02b6c19d48`">Terms of Use</a>
                <div v-if="show.manage" class="link-seperator">|</div>
                <a v-if="show.manage" :href="`https://support.${domain}/manage`">Manage</a>
            </div>
        </div>
        <div v-if="show.navigation" class="navigation-menu">
            <a :href="`https://support.${domain}`">Support</a>
            <a :href="`https://support.${domain}/docs`">Documentation</a>
            <router-link to="/">Plugins</router-link>
        </div>
        <div ref="layout" class="layout">
            <router-view v-on:top="scrollTop()" v-on:nav="resetMenus()" v-on:profile="showProfile()" class="router-view" />
            <sitemap />
        </div>
    </div>
</template>

<script>
    import Navigation from "@/components/navigation.vue";

    export default {
        components: { "navigation": Navigation },

        computed: {
            domain() {
                const uri = window.location.hostname.split(".");
                const athority = uri.pop();
                const domain = uri.pop();

                return `${domain}.${athority}`;
            },
        },

        data() {
            return {
                issuer: process.env.ISSUER_URL,
                authenticated: false,
                name: null,
                email: null,
                picture: null,
                show: {
                    profile: false,
                    navigation: false,
                    manage: false,
                },
            };
        },

        async mounted() {
            this.authenticated = await this.$authenticated;
            this.name = `${await this.$profile("given_name")} ${await this.$profile("family_name")}`;
            this.email = await this.$profile("email");
            this.picture = await this.$profile("picture");
            this.show.manage = ((await this.$hasRole("ADMINISTRATORS")) || (await this.$hasRole("SUPPORT")));
        },

        methods: {
            scrollTop() {
                window.scrollTo(0,0);
            },

            showProfile() {
                this.show.profile = true;
            },

            resetMenus() {
                this.show.profile = false;
            },
        },
    };
</script>

<style>
    @font-face {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: local("Montserrat Regular"), local("Montserrat-Regular"),
            url(./assets/montserrat.woff2) format("woff2");
        unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
    }

    @font-face {
        font-family: "Material Icons";
        font-style: normal;
        font-weight: 400;
        src: local("Material Icons"), local("MaterialIcons-Regular"),
            url(./assets/material.woff2) format("woff2");
    }

    html,
    body {
        margin: 0;
        padding: 0;
        overflow: overlay;
    }

    body::-webkit-scrollbar {
        background-color: #ffffff00;
        width: 7px;
        height: 7px;
    }

    body::-webkit-scrollbar-track {
        background-color: #ffffff00;
    }

    body::-webkit-scrollbar-track:hover {
        background-color: #ffffff00;
    }

    body::-webkit-scrollbar-thumb {
        background-color: #00000044;
        border-radius: 7px;
    }

    body::-webkit-scrollbar-thumb:hover {
        background-color: #00000077;
    }

    body::-webkit-scrollbar-button {
        display: none;
    }

    textarea,
    input[type='text'],
    input[type='button'],
    input[type='submit'] {
        -webkit-appearance: none;
        border-radius: 0;
    }

    textarea::-webkit-scrollbar {
        background-color: #ffffff00;
        width: 7px;
        height: 7px;
    }

    textarea::-webkit-scrollbar-track {
        background-color: #ffffff00;
    }

    textarea::-webkit-scrollbar-track:hover {
        background-color: #ffffff00;
    }

    textarea::-webkit-scrollbar-thumb {
        background-color: #00000044;
        border-radius: 7px;
    }

    textarea::-webkit-scrollbar-thumb:hover {
        background-color: #00000077;
    }

    textarea::-webkit-scrollbar-button {
        display: none;
    }
    
    iframe {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
    }

    .icon {
        font-family: "Material Icons";
        font-weight: normal;
        font-style: normal;
        font-size: 24px;
        line-height: 1;
        letter-spacing: normal;
        text-transform: none;
        display: inline-block;
        white-space: nowrap;
        word-wrap: normal;
        direction: ltr;
        font-feature-settings: "liga";
        -webkit-font-smoothing: antialiased;
    }

    a,
    a:link,
    a:active,
    a:visited {
        color: #feb400;
        text-decoration: none;
    }

    a:hover {
        text-decoration: underline;
    }

    pre {
        white-space: pre-wrap;
        word-break: break-word;
    }

    input[type="text"] {
        padding: 7px;
        width: 300px;
        margin: 10px 0;
        font-size: 14px;
        background: #fff;
        color: #000;
        border: 1px #e5e5e5 solid;
        border-radius: 3px;
    }

    input[type="text"]:focus {
        outline: 0 none;
        border-color: #feb400;
    }

    input[type="submit"] {
        width: 100px;
        font-size: 12pt;
        background: #feb400;
        color: #fff !important;
        text-decoration: none !important;
        display: inline-block;
        border: 1px #feb400 solid;
        border-radius: 3px;
        padding: 10px;
        cursor: pointer;
        user-select: none;
        margin: 0;
        white-space: pre;
    }

    input[type="submit"]:focus {
        outline: 0 none;
    }

    input[type="submit"]:hover {
        box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14),
            0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
    }

    .mobile-only {
        display: none;
    }

    @media (max-width: 815px) {
        .desktop-only {
            display: none !important;
        }

        .mobile-only {
            display: unset;
        }
    }

    .markdown-title p {
        margin: 0;
        padding: 0;
        overflow: hidden;
    }

    .markdown-title-multiline p {
        margin: 0;
        padding: 0;
    }

    #app {
        height: 100%;
        font-family: "Montserrat", sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: #3d3d3d;
    }

    #app iframe {
        position: unset;
        top: unset;
        left: unset;
        opacity: unset;
    }

    #app .button,
    #app .button:link,
    #app .button:active,
    #app .button:visited {
        background: #fff;
        color: #777 !important;
        text-decoration: none !important;
        font-size: 14px;
        text-align: center;
        display: inline-block;
        border: 1px #e5e5e5 solid;
        border-radius: 3px;
        padding: 10px;
        box-sizing: border-box;
        cursor: pointer;
        user-select: none;
        margin: 0 10px 0 0;
        white-space: pre;
    }

    #app .button:hover {
        box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14),
            0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
    }

    #app .button:focus {
        outline: 0 none;
    }

    #app .button-primary,
    #app .button-primary:link,
    #app .button-primary:active,
    #app .button-primary:visited {
        background: #feb400;
        color: #fff !important;
        border: 1px #feb400 solid;
    }

    #app .wraped {
        margin: 0 auto;
        width: 100%;
        max-width: 1280px;
    }

    #app .layout {
        display: flex;
        position: relative;
        min-height: 100vh;
        padding: 69px 0 0 0;
        overflow: auto;
        box-sizing: border-box;
    }

    #app .router-view {
        width: 100%;
        display: flex;
        position: relative;
        flex-direction: column;
        box-sizing: border-box;
        padding: 68px 0 187px 0;
    }

    #app .profile-menu {
        min-width: 280px;
        position: fixed;
        top: 54px;
        right: 10px;
        padding: 20px 20px 10px 20px;
        box-sizing: border-box;
        background: #fff;
        border-radius: 5px;
        user-select: none;
        z-index: 300;
        box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
            0 4px 5px 0 rgba(0, 0, 0, 0.14),
            0 1px 10px 0 rgba(0, 0, 0, 0.12);
    }

    #app .profile-menu .profile-account {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-content: center;
        align-items: center;
        box-sizing: border-box;
    }

    #app .profile-menu .profile-avatar {
        width: 70px;
        height: 70px;
        border-radius: 7px;
        margin: 10px;
    }

    #app .profile-menu .profile-name {
        font-size: 17px;
        font-weight: bold;
        margin: 0 0 2px 0;
    }

    #app .profile-menu .profile-email {
        font-size: 12px;
        margin: 0 0 20px 0;
    }

    #app .profile-menu .profile-action {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    #app .profile-menu .legal-links {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-content: center;
        align-items: center;
        padding: 20px 0 0 0;
        color: #e5e5e5;
        font-size: 12px;
    }

    #app .profile-menu .legal-links a,
    #app .profile-menu .legal-links a:link,
    #app .profile-menu .legal-links a:active,
    #app .profile-menu .legal-links a:visited {
        color: #9a9a9a;
        font-size: 12px;
        text-decoration: none;
    }

    #app .profile-menu .legal-links a:hover {
        color: #3d3d3d;
        text-decoration: none;
    }

    #app .profile-menu .legal-links .link-seperator {
        margin: 0 4px;
    }

    #app .navigation-menu {
        display: none;
    }

    #app .form-row {
        flex: 1;
        display: flex;
        flex-direction: row;
    }

    #app .form-row:empty {
        display: none;
    }

    #app .form-row .button {
        margin: 0 10px 20px 0;
    }

    #app .form-row .button:last-child {
        margin: 0 0 20px 0;
    }

    #app .form-row .form-group {
        flex: 1;
        display: flex;
        flex-direction: column;
        margin: 0 10px 10px 10px;
    }

    #app .form-row .form-group:first-child {
        margin: 0 10px 10px 0;
    }

    #app .form-row .form-group:last-child {
        margin: 0 0 10px 10px;
    }

    #app .form-row .form-group:only-child {
        margin: 0 0 10px 0;
    }

    #app .form-row .checkbox {
        margin: 0 0 10px 0;
    }

    #app .form-row .button-group {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
    }

    #app .form-row .button-group-fill {
        flex: 1;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }

    #app .form-row .form-fill {
        flex: 1;
    }

    @media (max-width: 815px) {
        #app .router-view {
            padding-bottom: 49px;
        }

        #app .profile-menu {
            border-radius: unset;
            box-shadow: unset;
            padding: 69px 0 0 0;
            position: fixed;
            top: 0;
            right: 0;
            width: 100%;
            height: 100%;
            z-index: 200;
        }

        #app .profile-menu .profile-account {
            border-top: 1px solid #e5e5e5;
        }

        #app .navigation-menu {
            display: flex;
            flex-direction: column;
            box-sizing: border-box;
            background: #fff;
            user-select: none;
            margin: 69px 0 0 0;
            position: fixed;
            top: 0;
            right: 0;
            width: 100%;
            height: 100%;
            z-index: 200;
        }

        #app .navigation-menu a,
        #app .navigation-menu a:link,
        #app .navigation-menu a:visited,
        #app .navigation-menu a:active {
            padding: 20px;
            margin: 0 20px;
            color: #3d3d3d;
            text-decoration: none;
            border-bottom: 1px solid #e5e5e5;
            cursor: pointer;
        }

        #app .navigation-menu a:last-child {
            border-bottom: 0 none;
        }
    }
</style>

<style>
    .markdown {
        padding: 5px 0 20px 0;
        font-size: 14px;
    }

    .markdown .emoji {
        height: 17px;
        vertical-align: middle;
    }

    .markdown .pl-c {
        color: #6a737d;
    }

    .markdown .pl-c1,
    .markdown .pl-s .pl-v {
        color: #005cc5;
    }

    .markdown .pl-e,
    .markdown .pl-en {
        color: #6f42c1;
    }

    .markdown .pl-smi,
    .markdown .pl-s .pl-s1 {
        color: #24292e;
    }

    .markdown .pl-ent {
        color: #22863a;
    }

    .markdown .pl-k {
        color: #d73a49;
    }

    .markdown .pl-s,
    .markdown .pl-pds,
    .markdown .pl-s .pl-pse .pl-s1,
    .markdown .pl-sr,
    .markdown .pl-sr .pl-cce,
    .markdown .pl-sr .pl-sre,
    .markdown .pl-sr .pl-sra {
        color: #032f62;
    }

    .markdown .pl-v,
    .markdown .pl-smw {
        color: #e36209;
    }

    .markdown .pl-bu {
        color: #b31d28;
    }

    .markdown .pl-ii {
        color: #fafbfc;
        background-color: #b31d28;
    }

    .markdown .pl-c2 {
        color: #fafbfc;
        background-color: #d73a49;
    }

    .markdown .pl-c2::before {
        content: "^M";
    }

    .markdown .pl-sr .pl-cce {
        font-weight: bold;
        color: #22863a;
    }

    .markdown .pl-ml {
        color: #735c0f;
    }

    .markdown .pl-mh,
    .markdown .pl-mh .pl-en,
    .markdown .pl-ms {
        font-weight: bold;
        color: #005cc5;
    }

    .markdown .pl-mi {
        font-style: italic;
        color: #24292e;
    }

    .markdown .pl-mb {
        font-weight: bold;
        color: #24292e;
    }

    .markdown .pl-md {
        color: #b31d28;
        background-color: #ffeef0;
    }

    .markdown .pl-mi1 {
        color: #22863a;
        background-color: #f0fff4;
    }

    .markdown .pl-mc {
        color: #e36209;
        background-color: #ffebda;
    }

    .markdown .pl-mi2 {
        color: #f6f8fa;
        background-color: #005cc5;
    }

    .markdown .pl-mdr {
        font-weight: bold;
        color: #6f42c1;
    }

    .markdown .pl-ba {
        color: #586069;
    }

    .markdown .pl-sg {
        color: #959da5;
    }

    .markdown .pl-corl {
        text-decoration: underline;
        color: #032f62;
    }

    .markdown .octicon {
        display: inline-block;
        vertical-align: text-top;
        fill: currentColor;
    }

    .markdown a {
        background-color: transparent;
    }

    .markdown a:active,
    .markdown a:hover {
        outline-width: 0;
    }

    .markdown strong {
        font-weight: inherit;
    }

    .markdown strong {
        font-weight: bolder;
    }

    .markdown h1 {
        margin: 0.67em 0;
    }

    .markdown img {
        border-style: none;
    }

    .markdown code,
    .markdown kbd,
    .markdown pre {
        font-family: monospace, monospace;
        font-size: 1em;
    }

    .markdown hr {
        box-sizing: content-box;
        height: 0;
        overflow: visible;
    }

    .markdown input {
        font: inherit;
        margin: 0;
    }

    .markdown input {
        overflow: visible;
    }

    .markdown [type="checkbox"] {
        box-sizing: border-box;
        padding: 0;
    }

    .markdown * {
        box-sizing: border-box;
    }

    .markdown input {
        font-family: inherit;
        font-size: inherit;
        line-height: inherit;
    }

    .markdown a {
        color: #feb400;
        text-decoration: none;
    }

    .markdown a:hover {
        text-decoration: underline;
    }

    .markdown strong {
        font-weight: 600;
    }

    .markdown hr {
        height: 0;
        margin: 15px 0;
        overflow: hidden;
        background: transparent;
        border: 0;
        border-bottom: 1px solid #e5e5e5;
    }

    .markdown hr::before {
        display: table;
        content: "";
    }

    .markdown hr::after {
        display: table;
        clear: both;
        content: "";
    }

    .markdown table {
        border-spacing: 0;
        border-collapse: collapse;
    }

    .markdown table th {
        padding: 10px;
        text-align: left;
        border-bottom: 1px #e5e5e5 solid;
        color: #3d3d3d;
    }

    .markdown table td {
        padding: 10px;
        background-color: #fff;
        border-bottom: 1px #e5e5e5 solid;
    }

    .markdown h1,
    .markdown h2,
    .markdown h3,
    .markdown h4,
    .markdown h5,
    .markdown h6 {
        margin-top: 0;
        margin-bottom: 0;
    }

    .markdown h1 {
        font-size: 22px;
        color: #3d3d3d;
        font-weight: 600;
    }

    .markdown h2 {
        font-size: 20px;
        font-weight: 600;
    }

    .markdown h3 {
        font-size: 18px;
        font-weight: 600;
    }

    .markdown h4,
    .markdown h5,
    .markdown h6 {
        font-size: 14px;
        font-weight: 600;
    }

    .markdown p {
        margin-top: 0;
        margin-bottom: 10px;
    }

    .markdown blockquote {
        margin: 0;
    }

    .markdown ul,
    .markdown ol {
        padding-left: 0;
        margin-top: 0;
        margin-bottom: 0;
    }

    .markdown ol ol,
    .markdown ul ol {
        list-style-type: lower-roman;
    }

    .markdown ul ul ol,
    .markdown ul ol ol,
    .markdown ol ul ol,
    .markdown ol ol ol {
        list-style-type: lower-alpha;
    }

    .markdown dd {
        margin-left: 0;
    }

    .markdown code {
        font-family: "SFMono-Regular", Consolas, "Liberation Mono", Menlo, Courier,
            monospace;
        font-size: 12px;
    }

    .markdown pre {
        margin-top: 0;
        margin-bottom: 0;
        font-family: "SFMono-Regular", Consolas, "Liberation Mono", Menlo, Courier,
            monospace;
        font-size: 12px;
    }

    .markdown .octicon {
        vertical-align: text-bottom;
    }

    .markdown .pl-0 {
        padding-left: 0 !important;
    }

    .markdown .pl-1 {
        padding-left: 4px !important;
    }

    .markdown .pl-2 {
        padding-left: 8px !important;
    }

    .markdown .pl-3 {
        padding-left: 16px !important;
    }

    .markdown .pl-4 {
        padding-left: 24px !important;
    }

    .markdown .pl-5 {
        padding-left: 32px !important;
    }

    .markdown .pl-6 {
        padding-left: 40px !important;
    }

    .markdown::before {
        display: table;
        content: "";
    }

    .markdown::after {
        display: table;
        clear: both;
        content: "";
    }

    .markdown > *:first-child {
        margin-top: 0 !important;
    }

    .markdown > *:last-child {
        margin-bottom: 0 !important;
    }

    .markdown a:not([href]) {
        color: inherit;
        text-decoration: none;
    }

    .markdown .anchor {
        float: left;
        padding-right: 4px;
        margin-left: -20px;
        line-height: 1;
    }

    .markdown .anchor:focus {
        outline: none;
    }

    .markdown p,
    .markdown blockquote,
    .markdown ul,
    .markdown ol,
    .markdown dl,
    .markdown table,
    .markdown pre {
        margin-top: 0;
        margin-bottom: 16px;
    }

    .markdown hr {
        height: 1px;
        padding: 0;
        margin: 24px 0;
        background-color: #e1e4e8;
        border: 0;
    }

    .markdown blockquote {
        padding: 0 1em;
        color: #6a737d;
        border-left: 0.25em solid #dfe2e5;
    }

    .markdown blockquote > :first-child {
        margin-top: 0;
    }

    .markdown blockquote > :last-child {
        margin-bottom: 0;
    }

    .markdown kbd {
        display: inline-block;
        padding: 3px 5px;
        font-size: 11px;
        line-height: 10px;
        color: #444d56;
        vertical-align: middle;
        background-color: #fafbfc;
        border: solid 1px #c6cbd1;
        border-bottom-color: #959da5;
        border-radius: 3px;
        box-shadow: inset 0 -1px 0 #959da5;
    }

    .markdown h1,
    .markdown h2,
    .markdown h3,
    .markdown h4,
    .markdown h5,
    .markdown h6 {
        margin-top: 24px;
        margin-bottom: 16px;
        font-weight: 600;
        line-height: 1.25;
    }

    .markdown h1 .octicon-link,
    .markdown h2 .octicon-link,
    .markdown h3 .octicon-link,
    .markdown h4 .octicon-link,
    .markdown h5 .octicon-link,
    .markdown h6 .octicon-link {
        color: #1b1f23;
        vertical-align: middle;
        visibility: hidden;
    }

    .markdown h1:hover .anchor,
    .markdown h2:hover .anchor,
    .markdown h3:hover .anchor,
    .markdown h4:hover .anchor,
    .markdown h5:hover .anchor,
    .markdown h6:hover .anchor {
        text-decoration: none;
    }

    .markdown h1:hover .anchor .octicon-link,
    .markdown h2:hover .anchor .octicon-link,
    .markdown h3:hover .anchor .octicon-link,
    .markdown h4:hover .anchor .octicon-link,
    .markdown h5:hover .anchor .octicon-link,
    .markdown h6:hover .anchor .octicon-link {
        visibility: visible;
    }

    .markdown h1 {
        padding-bottom: 0.3em;
        border-bottom: 1px solid #e5e5e5;
    }

    .markdown h2 {
        padding-bottom: 0.3em;
        border-bottom: 1px solid #e5e5e5;
    }

    .markdown ul,
    .markdown ol {
        padding-left: 2em;
    }

    .markdown ul ul,
    .markdown ul ol,
    .markdown ol ol,
    .markdown ol ul {
        margin-top: 0;
        margin-bottom: 0;
    }

    .markdown li {
        word-wrap: break-all;
    }

    .markdown li > p {
        margin-top: 16px;
    }

    .markdown li + li {
        margin-top: 0.25em;
    }

    .markdown dl {
        padding: 0;
    }

    .markdown dl dt {
        padding: 0;
        margin-top: 16px;
        font-size: 1em;
        font-style: italic;
        font-weight: 600;
    }

    .markdown dl dd {
        padding: 0 16px;
        margin-bottom: 16px;
    }

    .markdown table {
        width: 100%;
        overflow: auto;
    }

    .markdown table th {
        font-weight: 600;
    }

    .markdown table th,
    .markdown table td {
        padding: 6px 13px;
    }

    .markdown table tr {
        background-color: #fff;
    }

    .markdown table tr:nth-child(2n) {
        background-color: #fff;
    }

    .markdown img {
        max-width: 100%;
        box-sizing: content-box;
        background-color: #fff;
    }

    .markdown img[align="right"] {
        padding-left: 20px;
    }

    .markdown img[align="left"] {
        padding-right: 20px;
    }

    .markdown code {
        padding: 0.2em 0.4em;
        margin: 0;
        font-size: 85%;
        background-color: rgba(27, 31, 35, 0.05);
        border-radius: 3px;
    }

    .markdown pre {
        word-wrap: normal;
    }

    .markdown pre > code {
        padding: 0;
        margin: 0;
        font-size: 100%;
        word-break: normal;
        white-space: pre;
        background: transparent;
        border: 0;
    }

    .markdown .highlight {
        margin-bottom: 16px;
    }

    .markdown .highlight pre {
        margin-bottom: 0;
        word-break: normal;
    }

    .markdown .highlight pre,
    .markdown pre {
        padding: 16px;
        overflow: auto;
        font-size: 85%;
        line-height: 1.45;
        background-color: #f1f1f1;
        border-radius: 3px;
    }

    .markdown pre code {
        display: inline;
        max-width: auto;
        padding: 0;
        margin: 0;
        overflow: visible;
        line-height: inherit;
        white-space: pre-wrap;
        word-wrap: break-word;
        background-color: transparent;
        border: 0;
    }

    .markdown .full-commit .btn-outline:not(:disabled):hover {
        color: #005cc5;
        border-color: #005cc5;
    }

    .markdown kbd {
        display: inline-block;
        padding: 3px 5px;
        font: 11px "SFMono-Regular", Consolas, "Liberation Mono", Menlo, Courier,
            monospace;
        line-height: 10px;
        color: #444d56;
        vertical-align: middle;
        background-color: #fafbfc;
        border: solid 1px #d1d5da;
        border-bottom-color: #c6cbd1;
        border-radius: 3px;
        box-shadow: inset 0 -1px 0 #c6cbd1;
    }

    .markdown :checked + .radio-label {
        position: relative;
        z-index: 1;
        border-color: #0366d6;
    }

    .markdown .task-list-item {
        list-style-type: none;
    }

    .markdown .task-list-item + .task-list-item {
        margin-top: 3px;
    }

    .markdown .task-list-item input {
        margin: 0 0.2em 0.25em -1.6em;
        vertical-align: middle;
    }

    .markdown hr {
        border-bottom-color: #e5e5e5;
    }

    .markdown .token.comment,
    .markdown .token.prolog,
    .markdown .token.doctype,
    .markdown .token.cdata {
        color: #008000;
        font-style: italic;
    }

    .markdown .token.namespace {
        opacity: 0.7;
    }

    .markdown .token.string {
        color: #a31515;
    }

    .markdown .token.punctuation,
    .markdown .token.operator {
        color: #393a34;
    }

    .markdown .token.url,
    .markdown .token.symbol,
    .markdown .token.number,
    .markdown .token.boolean,
    .markdown .token.variable,
    .markdown .token.constant,
    .markdown .token.inserted {
        color: #36acaa;
    }

    .markdown .token.atrule,
    .markdown .token.keyword,
    .markdown .token.attr-value,
    .markdown .language-autohotkey .token.selector,
    .markdown .language-json .token.boolean,
    .markdown .language-json .token.number,
    .markdown code[class*="language-css"] {
        color: #0000ff;
    }

    .markdown .token.function {
        color: #393a34;
    }

    .markdown .token.deleted,
    .markdown .language-autohotkey .token.tag {
        color: #9a050f;
    }

    .markdown .token.selector,
    .markdown .language-autohotkey .token.keyword {
        color: #00009f;
    }

    .markdown .token.important,
    .markdown .token.bold {
        font-weight: bold;
    }

    .markdown .token.italic {
        font-style: italic;
    }

    .markdown .token.class-name,
    .markdown .language-json .token.property {
        color: #2b91af;
    }

    .markdown .token.tag,
    .markdown .token.selector {
        color: #800000;
    }

    .markdown .token.attr-name,
    .markdown .token.property,
    .markdown .token.regex,
    .markdown .token.entity {
        color: #ff0000;
    }

    .markdown .token.directive.tag .tag {
        background: #ffff00;
        color: #393a34;
    }
</style>

<style>
    .hljs-comment,
    .hljs-quote {
        color: #998;
        font-style: italic;
        word-break: break-word;
    }

    .hljs-keyword,
    .hljs-selector-tag,
    .hljs-subst {
        color: #333;
        font-weight: bold;
        word-break: break-word;
    }

    .hljs-number,
    .hljs-literal,
    .hljs-variable,
    .hljs-template-variable,
    .hljs-tag .hljs-attr {
        color: #008080;
        word-break: break-word;
    }

    .hljs-string,
    .hljs-doctag {
        color: #d14;
        word-break: break-word;
    }

    .hljs-title,
    .hljs-section,
    .hljs-selector-id {
        color: #900;
        font-weight: bold;
        word-break: break-word;
    }

    .hljs-subst {
        font-weight: normal;
        word-break: break-word;
    }

    .hljs-type,
    .hljs-class .hljs-title {
        color: #458;
        font-weight: bold;
        word-break: break-word;
    }

    .hljs-tag,
    .hljs-name,
    .hljs-attribute {
        color: #000080;
        font-weight: normal;
    }

    .hljs-regexp,
    .hljs-link {
        color: #009926;
        word-break: break-word;
    }

    .hljs-symbol,
    .hljs-bullet {
        color: #990073;
        word-break: break-word;
    }

    .hljs-built_in,
    .hljs-builtin-name {
        color: #0086b3;
        word-break: break-word;
    }

    .hljs-meta {
        color: #999;
        font-weight: bold;
        word-break: break-word;
    }

    .hljs-deletion {
        background: #fdd;
        word-break: break-word;
    }

    .hljs-addition {
        background: #dfd;
        word-break: break-word;
    }

    .hljs-emphasis {
        font-style: italic;
        word-break: break-word;
    }

    .hljs-strong {
        font-weight: bold;
        word-break: break-word;
    }
</style>